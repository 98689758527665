import React, { lazy, Suspense } from 'react';
import './App.css';
import Lottie from 'react-lottie-player';
import Preloader from './assets/lotties/preloader.json';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripe } from './lib/stripe';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEy);

const handleStripePayment = async (monto, tipo, precioId) => {
    const origin = window.location.origin;

    try {
        let session;
        if (tipo === 'único') {
            session = await stripe.checkout.sessions.create({
                payment_method_types: ['card'],
                line_items: [{
                    price_data: {
                        currency: 'mxn',
                        product_data: {
                            name: 'Pago único',
                        },
                        unit_amount: monto * 100,
                    },
                    quantity: 1,
                }],
                mode: 'payment',
                success_url: `${origin}/success?session_id={CHECKOUT_SESSION_ID}`,
                cancel_url: `${origin}/cart`,
            });
        } else if (tipo === 'mensual') {
            if (!precioId) {
                throw new Error('Se requiere un ID de precio para el pago mensual');
            }

            session = await stripe.checkout.sessions.create({
                payment_method_types: ['card'],
                line_items: [{
                    price: precioId,
                    quantity: 1,
                }],
                mode: 'subscription',
                success_url: `${origin}/success?session_id={CHECKOUT_SESSION_ID}`,
                cancel_url: `${origin}/cart`,
            });
        } else {
            throw new Error('Tipo de pago no válido');
        }

        window.location.href = session.url;
    } catch (error) {
        console.error(error);
    }
};

const Home = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./routes/home/home')), 5000);
    });
});

function App() {
    return (
        <div className="app">
            <Suspense fallback={<div className="avofi__loading">
                <Lottie
                    className="avofi__preloader"
                    loop
                    animationData={Preloader}
                    play
                />
            </div>}>
                <Elements stripe={stripePromise}>
                    <Home handleStripePayment={handleStripePayment} />
                </Elements>
            </Suspense>
        </div>
    );
}

export default App;


